import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'


const IntroSurvey = ({ open, close }) => {

  const rankOptions = [
    'Silver I (S1)',
    'Silver II (S2)',
    'Silver III (S3)',
    'Silver IV (S4)',
    'Silver Elite (SE)',
    'Silver Elite Master (SEM)',
    'Gold Nova I (GN1)',
    'Gold Nova II (GN2)',
    'Gold Nova III (GN3)',
    'Gold Nova Master (GNM)',
    'Master Guardian I (MG1)',
    'Master Guardian II (MG2)',
    'Master Guardian Elite (MGE)',
    'Distinguished Master Guardian (DMG)',
    'Legendary Eagle (LE)',
    'Legendary Eagle Master (LEM)',
    'Supreme Master First Class (SMFC)',
    'Global Elite (GE)',
  ]

  const [answers, setAnswers] = useState({})
  const [surveyCompleted, setSurveyCompleted] = useState(false)

  const updateAnswers = (event, field) => {
    const { value } = event.target
    setAnswers(prevAnswers => {
      const updatedAnswers = { ...prevAnswers }
      updatedAnswers[field] = value

      if ( 'playing-cs:go' in updatedAnswers
           && 'watch-cs:go' in updatedAnswers
           && 'age' in updatedAnswers
           && 'sex' in updatedAnswers
           && 'gender' in updatedAnswers
           && 'education' in updatedAnswers
           && 'work-hours' in updatedAnswers ) {
        setSurveyCompleted(true)
      } else {
        setSurveyCompleted(false)
      }

      return updatedAnswers
    })
  }

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Intro Survey</DialogTitle>
      <DialogContent style={{ minWidth: '750px' }}>
        <DialogContentText id="alert-dialog-description">
          Please answer these questions to start forecasting
        </DialogContentText>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">Do you play CS:GO?</FormLabel>
          <RadioGroup
            row
            aria-label="playing-cs:go"
            name="playing-cs:go"
            value={answers['playing-cs:go']}
            onChange={event => updateAnswers(event, 'playing-cs:go')}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {!!answers['playing-cs:go'] && answers['playing-cs:go'] === 'yes' && (
          <React.Fragment>
            <br />
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">How many years have you played CS:GO? Please enter a number.</FormLabel>
              <TextField
                margin="dense"
                id="years-playing-cs:go"
                label="Years playing CS:GO"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 150, step: 0.1 } }}
                fullWidth
                variant="outlined"
                value={answers['years-playing-cs:go']}
                onChange={event => updateAnswers(event, 'years-playing-cs:go')}
              />
            </FormControl>
            <br />
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">How many hours a month do you play CS:GO? Please enter a number.</FormLabel>
              <TextField
                margin="dense"
                id="hours-playing-cs:go"
                label="Hours playing CS:GO in a month"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 150, step: 0.1 } }}
                fullWidth
                variant="outlined"
                value={answers['hours-playing-cs:go']}
                onChange={event => updateAnswers(event, 'hours-playing-cs:go')}
              />
            </FormControl>
            <br />
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">Do you play CS:GO competitively?</FormLabel>
              <RadioGroup
                row
                aria-label="competitive-cs:go"
                name="competitive-cs:go"
                value={answers['competitive-cs:go']}
                onChange={event => updateAnswers(event, 'competitive-cs:go')}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            {!!answers['competitive-cs:go'] && answers['competitive-cs:go'] === 'yes' && (
              <React.Fragment>
                <br />
                <FormControl component="fieldset" style={{ minWidth: '500px', marginBottom: '20px' }}>
                  <InputLabel id="rank-cs:go">What is your rank?</InputLabel>
                  <Select
                    labelId="rank-cs:go"
                    id="rank-cs:go"
                    label="What is your rank?"
                    value={answers['rank-cs:go']}
                    onChange={event => updateAnswers(event, 'rank-cs:go')}
                  >
                    {rankOptions.map(rank => (
                      <MenuItem value={rank}>{rank}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">Do you watch CS:GO as an esport?</FormLabel>
          <RadioGroup
            row
            aria-label="watch-cs:go"
            name="watch-cs:go"
            value={answers['watch-cs:go']}
            onChange={event => updateAnswers(event, 'watch-cs:go')}
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>
        {!!answers['watch-cs:go'] && answers['watch-cs:go'] === 'yes' && (
          <React.Fragment>
            <br />
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">How many hours a month do you watch CS:GO as an esport? Please enter a number.</FormLabel>
              <TextField
                margin="dense"
                id="hours-watching-cs:go"
                label="Hours watching CS:GO in a month"
                type="number"
                InputProps={{ inputProps: { min: 0, max: 150, step: 0.1 } }}
                fullWidth
                variant="outlined"
                value={answers['hours-watching-cs:go']}
                onChange={event => updateAnswers(event, 'hours-watching-cs:go')}
              />
            </FormControl>
            <br />
            <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
              <FormLabel component="legend">Do you participate in community conversations about CS:GO? (Live chats or on social media.)</FormLabel>
              <RadioGroup
                row
                aria-label="participate-in-cs:go-community"
                name="participate-in-cs:go-community"
                value={answers['participate-in-cs:go-community']}
                onChange={event => updateAnswers(event, 'participate-in-cs:go-community')}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </React.Fragment>
        )}
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">What is your age in years?</FormLabel>
          <TextField
            margin="dense"
            id="age"
            label="Age"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 150 } }}
            fullWidth
            variant="outlined"
            value={answers.age}
            onChange={event => updateAnswers(event, 'age')}
          />
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">What sex were you assigned at birth?</FormLabel>
          <RadioGroup
            row
            aria-label="sex"
            name="sex"
            value={answers.sex}
            onChange={event => updateAnswers(event, 'sex')}
          >
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="female" control={<Radio />} label="Female" />
          </RadioGroup>
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">What is your gender identity?</FormLabel>
          <TextField
            margin="dense"
            id="gender"
            label="Gender"
            type="text"
            fullWidth
            variant="outlined"
            value={answers.gender}
            onChange={event => updateAnswers(event, 'gender')}
          />
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ minWidth: '500px', marginBottom: '20px' }}>
          <InputLabel id="education">What level of education have you achieved?</InputLabel>
          <Select
            labelId="education"
            id="education"
            label="What level of education have you achieved?"
            value={answers.education}
            onChange={event => updateAnswers(event, 'education')}
          >
            <MenuItem value="non-hs">Not a high school graduate</MenuItem>
            <MenuItem value="hs-ged">High school graduate or GED recipient</MenuItem>
            <MenuItem value="aa-as">Associate degree (for example: AA, AS)</MenuItem>
            <MenuItem value="bs">Bachelor’s degree (for example: BA, AB, BS)</MenuItem>
            <MenuItem value="ms">Master’s degree</MenuItem>
            <MenuItem value="md-dvm-jd-psyd">Professional degree or professional doctorate (for example, MD, DVM, JD, PsyD)</MenuItem>
            <MenuItem value="other-phd-edh">Other Doctorate degree (for example: PhD or EdD)</MenuItem>
          </Select>
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">How many hours do you work at your job in a typical week? Please enter a number.</FormLabel>
          <TextField
            margin="dense"
            id="work-hours"
            label="Work Hours"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 168 } }}
            fullWidth
            variant="outlined"
            value={answers['work-hours']}
            onChange={event => updateAnswers(event, 'work-hours')}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(answers)} color="primary" disabled={!surveyCompleted}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default IntroSurvey
