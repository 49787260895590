import getHeaders from '../helper/header'


const postSurveyData = (type, surveyData) => {

  const url = `/api/surveys/submit/${type}`
  const headers = getHeaders()

  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(surveyData),
    })
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error))
  })

}

export default postSurveyData
