import axios from 'axios'

export const postRegister = (username, password, email) => {
  const url = '/api/accounts/register/'
  return axios.post(url, {
    username,
    password,
    email,
  })
}

export const postToken = (username, password) => {
  const url = '/api/accounts/token/'
  return axios.post(url, {
    username,
    password,
  })
}

export const postAuthenticate = (username, password) => {
  const url = '/api/accounts/authenticate/'
  return axios.post(url, {
    username,
    password,
  })
}

export const postAuthenticateProlific = ({ PROLIFIC_PID, SESSION_ID, STUDY_ID }) => {
  const url = '/api/accounts/authenticate/prolific/'
  return axios.post(url, {
    PROLIFIC_PID,
    SESSION_ID,
    STUDY_ID,
  })
}
