import React from 'react'

import Layout from '../../../components/Layout/Layout'
import { isLoggedIn } from '../../../helper/auth'
import PageNotFound from '../PageNotFound/PageNotFound'


const ComingSoon = () => {
  return (
    <Layout>
      {isLoggedIn() ? (
        <PageNotFound
          message1="Coming Soon"
          message2="This page is under construction. Check back later!"
        />
      ) : (
        <PageNotFound
          message1="Please Login"
          message2="This page is only accessible to authenticated users"
        />
      )}
    </Layout>
  )
}


export default ComingSoon
