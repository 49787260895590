import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material'


const ExitSurvey = ({ open, close }) => {

  const [answers, setAnswers] = useState({})
  const [surveyCompleted, setSurveyCompleted] = useState(false)

  const updateAnswers = (event, field) => {
    const { value } = event.target
    setAnswers(prevAnswers => {
      const updatedAnswers = { ...prevAnswers }
      updatedAnswers[field] = value

      if ( 'forecasting-difficulty' in updatedAnswers && !!updatedAnswers['forecasting-difficulty']
           && 'information-improvements' in updatedAnswers && !!updatedAnswers['information-improvements']
           && 'enhanced-functionality' in updatedAnswers && !!updatedAnswers['enhanced-functionality'] ) {
        setSurveyCompleted(true)
      } else {
        setSurveyCompleted(false)
      }

      return updatedAnswers
    })
  }

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Exit Survey</DialogTitle>
      <DialogContent style={{ minWidth: '750px' }}>
        <DialogContentText id="alert-dialog-description">
          Please answer these questions to complete your participation.<br />
          The submit button will redirect you back to the <em>Prolific </em>
          platform with the completion code.
        </DialogContentText>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">How easy/hard was it to generate the requested forecasts?</FormLabel>
          <TextField
            margin="dense"
            id="forecasting-difficulty"
            label="Forecasting Difficulty"
            type="text"
            fullWidth
            variant="outlined"
            value={answers['forecasting-difficulty']}
            onChange={event => updateAnswers(event, 'forecasting-difficulty')}
          />
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">What information (that was not already provided by the experimental interface) would have improved your forecasts?</FormLabel>
          <TextField
            margin="dense"
            id="information-improvements"
            label="Information Improvements"
            type="text"
            fullWidth
            variant="outlined"
            value={answers['information-improvements']}
            onChange={event => updateAnswers(event, 'information-improvements')}
          />
        </FormControl>
        <br />
        <FormControl component="fieldset" style={{ marginBottom: '20px' }}>
          <FormLabel component="legend">Which functionality of the platform would you like to see enhanced?</FormLabel>
          <TextField
            margin="dense"
            id="enhanced-functionality"
            label="Enhanced Functionality"
            type="text"
            fullWidth
            variant="outlined"
            value={answers['enhanced-functionality']}
            onChange={event => updateAnswers(event, 'enhanced-functionality')}
          />
        </FormControl>
        <br />
        {surveyCompleted && (
          <h3 style={{ color: 'red', display: 'inline' }}>The completion code is: <em><pre>COESEKT4</pre></em></h3>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => close(answers)} color="primary" disabled={!surveyCompleted}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default ExitSurvey
