import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles'

import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import QuestionChart from './components/Charts/TimeSeries/TimeSeriesChart'
import QuestionPage from './containers/Pages/Questions/QuestionsPage'
import Forecast from './containers/Pages/Forecast/Forecast'
import Register from './containers/Pages/Register/Register'
import SignIn from './containers/Pages/SignIn/SignIn'
import Home from './containers/Pages/Home/Home'
import ComingSoon from './containers/Pages/ComingSoon/ComingSoon'
import Account from './containers/Pages/Account/Account'
import Tournaments from './containers/Pages/Tournaments/Tournaments'
import TournamentDetail from './containers/Pages/Tournaments/TournamentDetail'
import FaqPage from './containers/Pages/Faq/FaqPage'
import NewsPage from './containers/Pages/News/NewsPage'
import TrainingModule from './components/Training/TrainingModule'
import IntroSurvey from './components/IntroSurvey'

import postSurveyData from './queries/survey.query'
import { handleTokenAndStoreUserInfo } from './helper/token_decode'
import { postToken, postAuthenticate, postAuthenticateProlific } from './mutations/Account/account.mutation'


let theme = createTheme({
  palette: {
    primary: {
      main: '#673AB7', // purple
    },
    secondary: {
      main: '#9575CD', // green
    },
    background: {
      main: '#F5F5F5', // grey
      darkpurpletournament: '#0E0221',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          WebkitFontSmoothing: 'auto',
        },
        body: {
          background: '#F5F5F5',
          color: '#333',
        },
        modal: {
          header: {
            fontSize: '26px',
            margin: '5px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          color: '#F5F5F5',
          background: '#6a3fb2',
          textDecoration: 'none',
          cursor: 'pointer',
          borderRadius: '5px',
          ':hover': {
            background: '#673AB7',
          },
          '&.Mui-disabled': {
            color: '#F5F5F5',
            background: 'rgba(106, 63, 178, 0.5)',
            cursor: 'not-allowed',
          },
        },
      },
    },
  },
})


theme = responsiveFontSizes(theme)


const App = () => {

  const [openIntroSurvey, setOpenIntroSurvey] = useState(false)

  useEffect(() => {

    const session = {}
    const locationQuery = new URLSearchParams(window.location.search)

    if ( locationQuery.has('PROLIFIC_PID') ) {
      session.PROLIFIC_PID = locationQuery.get('PROLIFIC_PID')
    }

    if ( locationQuery.has('STUDY_ID') ) {
      session.STUDY_ID = locationQuery.get('STUDY_ID')
    }

    if ( locationQuery.has('SESSION_ID') ) {
      session.SESSION_ID = locationQuery.get('SESSION_ID')
    }

    if ( !!session.PROLIFIC_PID && !!session.STUDY_ID && !!session.SESSION_ID ) {
      postAuthenticateProlific(session)
        .then(({ data }) => {
          postToken(session.PROLIFIC_PID, session.PROLIFIC_PID)
            .then((response) => {
              const accessToken = response.data.access
              handleTokenAndStoreUserInfo(accessToken)
              return postAuthenticate(session.PROLIFIC_PID, session.PROLIFIC_PID)
            })
            .then(() => {
              if ( data.survey ) {
                setOpenIntroSurvey(true)
              } else {
                window.location = 'https://sage.isi.edu/tournaments'
              }
            })
            .catch((error) => {
              toast.error(error.response?.data.detail || 'Internal Server Error Occured')
            })
        })
    }
  }, [])

  const closeIntroSurvey = (data) => {
    setOpenIntroSurvey(false)
    postSurveyData('intro', data)
      .then(() => {
        window.location = 'https://sage.isi.edu/tournaments'
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: theme.palette.background.main,
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <CssBaseline />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/landing" element={<ComingSoon />} />
            <Route path="/training" element={<TrainingModule />} />
            <Route path="/news" element={<ComingSoon />} />
            <Route path="/training" element={<Home />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/questions" element={<QuestionPage />} />
            <Route
              path="/tournament/:tournamentid/questions/:questionid"
              element={<Forecast />}
            />
            <Route path="/questions/:questionid" element={<Forecast />} />
            <Route path="/question" element={<QuestionChart />} />
            <Route path="/account" element={<Account />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route
              path="/tournament/:tournamentId"
              element={<TournamentDetail />}
            />
          </Routes>
        </BrowserRouter>
        <IntroSurvey open={openIntroSurvey} close={closeIntroSurvey} />
      </div>
    </ThemeProvider>
  )
}


export default App
